import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import useStyles from "../styles/styles.js";
import user from "../images/user.png";
import account from "../images/cloud_account.png";
const Pricing = () => {
  let perAccountPrice = "$10/month";
  let perUserPrice = "$5/month";
  const classes = useStyles();
  return (
    <>
      <Box id="pricing" className={classes.PricingheroBox}>
        <Grid container className={classes.sectionGridContainer}>
          <Grid item xs={12} md={4} textAlign="center">
            <img
              src={account}
              alt="My Team"
              style={{ height: "100px", width: "100px" }}
            />
            <Typography
              variant="h3"
              marginTop={5}
              style={{ color: "white", fontWeight: "bold" }}
              className={classes.subtitle}
            >
              Fair & Flexible Pricing - Pay-As-You-Go
            </Typography>
            <Typography
              variant="h6"
              style={{ color: "white", fontSize: "18px" }}
              className={classes.subtitle}
            >
              Our pricing is fair and flexible, following a{" "}
              <i style={{ color: "yellow", fontWeight: "bold" }}>
                pay-as-you-go model{" "}
              </i>
            </Typography>
            <a href="#contact" className="btn btn-custom btn-md page-scroll">
              Start Today
            </a>{" "}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, minHeight: "400px" }}>
        <Grid container className={classes.sectionGridContainer}>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.sectionGridItem}
            style={{
              background: "white",
            }}
          >
            <img
              src={account}
              alt="My Team"
              style={{ height: "60px", width: "60px" }}
            />
            <Typography
              my={1}
              variant="body1"
              style={{
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                padding: "0px",
              }}
              className={classes.subtitle}
            >
              Per Cloud Account
            </Typography>
            <Typography
              my={1}
              variant="body1"
              style={{ color: "gray", fontSize: "18px", padding: "0px" }}
              className={classes.subtitle}
            >
              {perAccountPrice}
            </Typography>
            <hr
              style={{
                backgroundColor: "gray",
                width: "100%",
                margin: "0px",
                padding: "0px",
              }}
            />

            <Typography
              my={1}
              variant="body2"
              style={{ color: "gray", fontSize: "14px", padding: "0px" }}
              className={classes.subtitle}
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                Unlimited{" "}
              </span>
              Role Creation
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "gray", fontSize: "14px", padding: "0px" }}
              className={classes.subtitle}
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                Unlimited{" "}
              </span>
              Groups Creation{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.sectionGridItem}
            style={{
              background: "white",
            }}
          >
            <img
              src={user}
              alt="My Team"
              style={{ height: "60px", width: "60px" }}
            />
            <Typography
              my={1}
              variant="body1"
              style={{
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                padding: "0px",
              }}
              className={classes.subtitle}
            >
              Per User Plan
            </Typography>
            <Typography
              my={1}
              variant="body1"
              style={{ color: "gray", fontSize: "18px", padding: "0px" }}
              className={classes.subtitle}
            >
              {perUserPrice}
            </Typography>
            <hr
              style={{
                backgroundColor: "gray",
                width: "100%",
                margin: "0px",
                padding: "0px",
              }}
            />

            <Typography
              my={1}
              variant="body2"
              style={{ color: "gray", fontSize: "14px", padding: "0px" }}
              className={classes.subtitle}
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                Unlimited{" "}
              </span>
              User Creation
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "gray", fontSize: "14px", padding: "0px" }}
              className={classes.subtitle}
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                Assign & Revoke{" "}
              </span>
              access
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Pricing;
