import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import myteam from "../images/home.png";
import useStyles from "../styles/styles.js";

const Homepage = () => {
  const classes = useStyles();

  return (
    <Box id="home" className={classes.heroBox}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={7} sx={{ mt: "60px" }}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Easying Multi-Cloud Access Management from a Unified Dashboard{" "}
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "16px", fontWeight: "bold", padding: "0px" }}
            className={classes.subtitle}
          >
            Single Identity for Multiple AWS Account Access.
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "16px", fontWeight: "bold", padding: "0px" }}
            className={classes.subtitle}
          >
            Integration made easy with Dedicated CIO.
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "16px", fontWeight: "bold", padding: "0px" }}
            className={classes.subtitle}
          >
            100% Data Security.
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "16px", fontWeight: "bold" }}
            className={classes.subtitle}
          >
            Easy On / Off Boarding{" "}
          </Typography>
         
          <a href="#features" className="btn btn-custom btn-lg page-scroll">
            Explore Features
          </a>{" "}
        </Grid>
        <Grid item md={5}>
          <img src={myteam} alt="My Team" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Homepage;
