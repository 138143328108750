import React from "react";
import { Box } from "@mui/material";
import logo from "../images/uniteds.png";
import "../App.css";
export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top navbar-custom"
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a href="#home">
            <Box
              component="img"
              sx={{
                height: 50,
                width: 50,
                m: 0,
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={logo}
            />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#home" className="page-scroll custom-link">
                Home
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll custom-link">
                Features
              </a>
            </li>
            <li>
              <a href="#pricing" className="page-scroll custom-link">
                Pricing
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll custom-link">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
