import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import roleAccess from "../images/role_access.png";
import Cloud_credential from "../images/Cloud_credential.png";
import CustomIdentityProvider from "../images/CustomIdentityProvider.png";
import EasyOnOffboarding from "../images/EasyOnOffboarding.png";
import EasyUserGroups from "../images/EasyUserGroups.png";
import Manageaws from "../images/Manageaws.png";
import MFASupport from "../images/MFASupport.png";
import SocialLoginSupport from "../images/SocialLoginSupport.png";
import useStyles from "../styles/styles";

const Features = () => {
  const classes = useStyles();

  const sectionItems = [
    {
      id: 1,
      icon: Manageaws,
      sentence: "Manage Multiple AWS Account Access",
      desc: "The Unified Dashboard streamlines the process of managing access for multiple AWS accounts, eliminating the need for individual user and access management for each account. This simplifies access management across multiple AWS accounts.",
    },
    {
      id: 2,
      icon: MFASupport,
      sentence: "MFA Support",
      desc: "Cloud Unify also enables Multi-Factor Authentication (MFA), allowing you to utilize Google Authenticator or Microsoft Authenticator for enhanced security.",
    },
    {
      id: 3,
      icon: roleAccess,
      sentence: "Easy Roles based Access Management",
      desc: "Cloud Unify empowers administrators to create roles within AWS accounts and seamlessly map them into the Unified Dashboard Access Management. This provides your teams with fine-grained control over access to AWS accounts, enhancing security and facilitating efficient management.",
    },
    {
      id: 7,
      icon: Cloud_credential,
      sentence: "Cloud Credentials not saved ",
      desc: "Cloud Unify ensures the utmost security by not storing any sensitive data, including credentials for your AWS account. This commitment to data protection makes Cloud Unify a highly secure platform for your business.",
    },

    {
      id: 8,
      icon: CustomIdentityProvider,
      sentence: "Custom Identity Provider Support",
      desc: "Cloud Unify also offers an identity provider for your organization, enabling you to use a single identity across all AWS accounts.",
    },
    {
      id: 5,
      icon: EasyUserGroups,
      sentence: "Easy User/Groups Management",
      desc: "Cloud Unify enables you to effortlessly create users and user groups, assigning them roles that are mapped to fine-grained access controls for your AWS account. This simplifies and enhances the efficiency of access management processes.",
    },

    {
      id: 4,
      icon: EasyOnOffboarding,
      sentence: "Easy On-boarding/Off-boarding of Teams",
      desc: "Cloud Unify simplifies the onboarding and offboarding processes for contractors, developers, and other team members by providing fine-grained access control and management capabilities.",
    },

    {
      id: 6,
      icon: SocialLoginSupport,
      sentence: "Social Login Support",
      desc: "Cloud Unify seamlessly integrates with your social login, whether it's through Google Plus or Microsoft, significantly reducing time spent on user management across multiple AWS accounts.",
    },
  ];
  return (
    <Box id="features" sx={{ flexGrow: 1, minHeight: "400px" }}>
      <Grid container className={classes.sectionGridContainer}>
        {sectionItems.map((item) => (
          <Grid
            item
            xs={12}
            md={3.5}
            minHeight={300}
            key={item.id}
            className={classes.sectionGridItem}
          >
            <img
              src={item.icon}
              alt="My Team"
              style={{ height: "100px", width: "100px" }}
            />
            <Typography
              variant="h4"
              marginTop={5}
              style={{ color: "black", fontWeight: "bold" }}
              className={classes.subtitle}
            >
              {item.sentence}
            </Typography>
            <Typography
              variant="h6"
              style={{ color: "black", fontSize: "18px" }}
              className={classes.subtitle}
            >
              {item.desc}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
