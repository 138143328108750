import React from "react";
import { Navigation } from "./components/Navigation";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Homepage from "./components/Homepage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Features from "./components/Features";
import Pricing from "./components/Pricing";
import ContactUs from "./components/ContactUs";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Navigation />
      <Homepage />
      <Features />
      <Pricing />
      <ContactUs />
    </ThemeProvider>
  );
};

export default App;
