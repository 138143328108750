import { useState } from "react";
import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import useStyles from "../styles/styles.js";
import SendEmail from "../api/SendEmail.jsx";
import ReCAPTCHA from "react-google-recaptcha";
const ContactUs = (props) => {
  const recaptchaRef = React.createRef();

  const [values, setValues] = useState({
    name: "",
    email: "",
    companyName: "",
    mobile: "",
    message: "",
  });
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  const [showMessage, setShowMessage] = useState(true);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState("250");
  const [isDisabled, setIsDisabled] = useState(false);

  const classes = useStyles();
  const checkCaptcha = () => {
    console.log(isCaptchaSuccessful);
    setIsCaptchaSuccess(true);
    setMessage("");
    showMessage(false);
  };

  const handleChange = (e) => {
    const nextFormState = {
      ...values,
      [e.target.name]: e.target.value,
    };
    setValues(nextFormState);
  };
  const sendEmailResponse = async (data) => await SendEmail(data);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isCaptchaSuccessful) {
      setShowMessage(true);
      setMessage("Are you human ?");
      setStatusCode(100);
      setTimeout(() => {
        setShowMessage(false);
        setMessage("");
        setStatusCode("");
      }, 3000);
      return;
    }
    console.log(values);
    setIsDisabled(true);
    const data = {
      from: "cloud-unify",
      username: values.name,
      country: "India",
      companyName: values.companyName,
      companyEmail: values.email,
      mobileNumber: values.mobile,
      description: values.message,
    };
    const apiResponse = sendEmailResponse(data);
    apiResponse.then((result) => {
      setShowMessage(true);
      if (result.data === "250") {
        setStatusCode("250");
        setMessage(
          "Thanks for Contacting Us. Our team will get back to you soon."
        );
      } else {
        setStatusCode("100");
        setMessage(
          "Server is busy. We are not able to accept you email.Please try again"
        );
      }
      setTimeout(() => {
        setShowMessage(false);
        setStatusCode("");
        setMessage("");
        setIsDisabled(false);
        setValues({
          name: "",
          email: "",
          companyName: "",
          mobile: "",
          message: "",
        });
        recaptchaRef.current.reset();
      }, 4000);
    });
  };
  return (
    <>
      <Box id="contact" className={classes.ContactHeroBox}>
        <Grid container className={classes.contactGridContainer}>
          <Grid item xs={12} md={8} sx={{ mt: "60px" }}>
            <Typography
              variant="h2"
              style={{ padding: "0px" }}
              fontWeight={700}
              className="text-black"
            >
              Get In Touch{" "}
            </Typography>
            <Divider
              style={{
                backgroundColor: "white",
                width: "25%",
              }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              Please fill out the form below to send us an email and we will get
              back to you.
            </p>

            <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      style={{ borderRadius: "5px" }}
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      style={{ borderRadius: "5px" }}
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="CompanyName"
                      style={{ borderRadius: "5px" }}
                      name="companyName"
                      className="form-control"
                      placeholder="Company Name"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      style={{ borderRadius: "5px" }}
                      className="form-control"
                      placeholder="Mobile"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  style={{ borderRadius: "5px" }}
                  required
                  onChange={handleChange}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <ReCAPTCHA
                sitekey="6LczCsImAAAAALBrNm2G8_atqf61-aLtJtBxA0r3"
                onChange={checkCaptcha}
                ref={recaptchaRef}
              />
              {showMessage ? (
                <>
                  {statusCode === "250" ? (
                    <p
                      style={{
                        color: "#00ff3a",
                        fontSize: "16px",
                      }}
                    >
                      {message}
                    </p>
                  ) : (
                    <p style={{ color: "yellow", fontSize: "16px" }}>
                      {message}
                    </p>
                  )}
                </>
              ) : null}

              <button
                type="submit"
                disabled={isDisabled}
                className="btn btn-custom btn-lg"
              >
                {isDisabled ? "Sending..." : "Send Message"}
              </button>
            </form>
          </Grid>
          <Grid item md={4} sx={12}>
            <div className="col-md-offset-1 contact-info">
              <Typography
                variant="h4"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Contact Info
              </Typography>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                <span>
                  <i className="fa fa-map-marker"></i> &nbsp; Address
                </span>
              </p>
              <p
                style={{
                  marginBottom: "30px",
                }}
              >
                SNeT Labs, 91 Springboard, Mohan Co-operative, New Delhi-110044
              </p>

              <p
                style={{
                  marginTop: "10px",
                }}
              >
                <span>
                  <i className="fa fa-envelope-o"></i> &nbsp; Email
                </span>
              </p>
              <p
                style={{
                  marginBottom: "30px",
                }}
              >
                sales@intraas.com{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
      <div className="col-md-12">
        <div className="row">
          <div className="social"></div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center ">
          <Typography variant="h6" color="black" style={{ fontWeight: "bold" }}>
            &copy; 2023 SNeT Labs Pvt. Ltd.
            <a
              href="https://www.intraas.com"
              style={{ color: "blue", fontSize: "14px" }}
              rel="nofollow"
              target="blank_"
            >
              {" "}
              IntraaS
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
